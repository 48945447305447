<template>
  <div class="container">
    <h4 class="mb-5">Pension Report | Application List</h4>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Applications</h5>
        <SearchApplications
          type="application"
          @reset="handleReset"
          @result="handleResult"
        />
        <ApplicationList
          :search-application="searchApplicationResult"
          @selected="handleSelect"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import ApplicationList from "@/components/lists/ApplicationList";
import SearchApplications from "@/components/search/SearchApplications";

export default {
  name: "Aggregation Report",
  data: () => ({
    searchApplicationResult: null
  }),
  components: {
    ApplicationList,
    SearchApplications
  },
  methods: {
    ...mapMutations(["setApplicationUuid"]),
    handleReset() {
      this.searchApplicationResult = null;
    },
    handleResult(result) {
      this.searchApplicationResult = result;
    },
    handleSelect(uuid) {
      this.setApplicationUuid(uuid);
      this.$router.push({
        name: "Aggregation Traced Pensions List",
        params: {
          uuid: uuid
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
